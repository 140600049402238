<template>
    <error-content code="401" desc="Oh~~您的页面好像飞走了~" :src="src"/>
</template>

<script>

import error401 from '@/assets/images/error/error-404.svg'
import errorContent from './index.vue'
export default {
    components: {
        errorContent
    },
    data () {
        return {
            src: error401
        }
    }
}
</script>

<style>

</style>